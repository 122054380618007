import { Frame, IndexFiltersMode, TextField, TopBar } from '@shopify/polaris';
import { PropsWithChildren, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useUser } from 'src/contexts/AuthProvider';
import { useToken } from 'src/contexts/TokenContext';
import { useLegacyNavigation } from './NavigationContainer';
import PolarisNavigation from './PolarisNavigation';
import { useCurrentStore } from 'src/hooks/Store';
import { Link } from 'react-router-dom';
import { useToggle } from 'src/contexts/ToggleProvider';
import { OverLayNavigation } from './Overlay/OverlayNavigation';
import { ImageSearchPopup } from 'src/screens/Inventory/components/popup/ImageSearchPopup';

function NavigationTopbar({
  onMobileNavigationToggle,
  role,
  name,
  onLogout,
}: {
  onMobileNavigationToggle: () => void;
  role: string;
  name: string;
  onLogout: () => void;
}) {
  const history = useHistory();
  const { location } = useHistory();
  const query = new URLSearchParams(location.search);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [productCode, setProductCode] = useState(query.get('code') || '');
  const { setLegacyNavigation } = useLegacyNavigation();
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const toggleIsUserMenuOpen = useCallback(() => setIsUserMenuOpen(isUserMenuOpen => !isUserMenuOpen), []);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleSearchChange = useCallback(value => {
    setSearchValue(value);
  }, []);

  const handleSearchProductChange = useCallback(value => {
    setProductCode(value);
  }, []);

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            { content: 'Setup Two Factor', url: '/security/two-factor' },
            { content: 'Switch to old admin', onAction: () => setLegacyNavigation({ legacy: true }) },
            {
              content: 'Online Support Techniques',
              onAction: () =>
                window.open(
                  'https://docs.google.com/document/d/1T2wCHk_QqprLypYxgIFeP8tGBwH0QrYZYE0A7XuTQCI/edit',
                  '_blank',
                ),
            },
            {
              content: 'Aftersales Support Technique',
              onAction: () =>
                window.open(
                  'https://docs.google.com/document/d/1uUpuexzgLa-y-7Mj-5V238oGjgNs7enF6etrXZkqBQQ/edit',
                  '_blank',
                ),
            },
          ],
        },
        {
          items: [{ content: 'Logout', onAction: onLogout }],
        },
      ]}
      name={name}
      detail={role}
      initials={name[0]}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      history.push(`/customers?phone=${searchValue}`);
    }
  };

  const onProductCodeSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (query.get('code') === productCode) {
        history.push(`/product/search?code=`);
        await sleep(1);
      }
      history.push(`/product/search?code=${productCode}`);
    }
  };

  const searchFieldMarkup = (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <div onKeyPress={onProductCodeSearch} className="relative">
        <TextField
          label=""
          onChange={handleSearchProductChange}
          value={productCode}
          placeholder="Enter product code"
          autoComplete="off"
        />
        <div className="absolute top-[25%] right-[2%] max-sm:right-[3%] max-md:right-[2%] max-xl:right-[2%] z-[999]">
          <ImageSearchPopup
            onImageSearchResult={value => {
              if (value.length > 0) {
                history.push(`/product/search?filter=${IndexFiltersMode.Filtering}`);
              }
            }}
          />
        </div>
      </div>
      <div onKeyPress={onKeyPress} style={{ width: '300px', paddingLeft: '20px' }}>
        <TextField
          label=""
          onChange={handleSearchChange}
          value={searchValue}
          placeholder="Search for customer"
          autoComplete="off"
        />
      </div>
    </div>
  );

  return (
    <TopBar
      showNavigationToggle
      onNavigationToggle={onMobileNavigationToggle}
      userMenu={userMenuMarkup}
      searchField={searchFieldMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      secondaryMenu={
        <div style={{ fontSize: 20, paddingRight: 24 }}>
          <Link to="/chat" style={{ color: 'inherit' }}>
            <i className="os-icon os-icon-mail-14"></i>
          </Link>
        </div>
      }
    />
  );
}

export default function PolarisNavigationContainer(props: PropsWithChildren<unknown>) {
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);
  const { setToken } = useToken();
  const storeId = useCurrentStore();
  const user = useUser();
  const { open } = useToggle();

  const navigationMarkup = !!open ? <PolarisNavigation /> : null;
  const topBarMarkup = (
    <NavigationTopbar
      onMobileNavigationToggle={() => setShowMobileNavigation(true)}
      role={(user.role || '') + (' (Store ' + storeId + ')')}
      name={user.name || ''}
      onLogout={() => {
        setToken('');
      }}
    />
  );

  return (
    <Frame
      logo={{
        width: 32,
        topBarSource: '/logo_l192.png',
        url: '/',
        accessibilityLabel: 'L192 Admin',
      }}
      navigation={navigationMarkup}
      topBar={topBarMarkup}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={() => setShowMobileNavigation(false)}
    >
      {!open && <OverLayNavigation />}
      {props.children}
    </Frame>
  );
}
