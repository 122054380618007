/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';

const SearchImageProductContext = React.createContext<{
  file: any;
  setFile: (v: any) => void;
  hash: any;
  setHash: (v: any) => void;
  productIds: any[];
  setProductIds: (v: any) => void;
}>({
  file: null,
  setFile: v => {
    //
  },
  hash: '',
  setHash: v => {
    //
  },
  productIds: [],
  setProductIds: v => {
    //
  },
});

export function useSearchImageProduct() {
  return useContext(SearchImageProductContext);
}

export function SearchImageProductProvider(props: PropsWithChildren<unknown>) {
  const [file, setFile] = useState<File | null>();
  const [hash, setHash] = useState<any>(null);
  const [productIds, setProductIds] = useState<any[]>([]);

  return (
    <SearchImageProductContext.Provider
      value={useMemo(() => {
        return {
          file,
          setFile,
          hash,
          setHash,
          productIds,
          setProductIds,
        };
      }, [file, setFile, hash, setHash, productIds, setProductIds])}
    >
      {props.children}
    </SearchImageProductContext.Provider>
  );
}
