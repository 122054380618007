import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { Checkbox } from '../../../components/form/FormComponent';
import { useHistory, useLocation } from 'react-router';
import { Icon, Modal, Select, TextField } from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';

// const TRAFFIC_LEVEL_LIST = [
//   { text: '0', value: '0' },
//   { text: '1', value: '1' },
//   { text: '2', value: '2' },
//   { text: '3', value: '3' },
//   { text: '4', value: '4' },
// ];

const QUERY_SETTING = gql`
  query MessageTemplate($option: SettingType!) {
    setting(option: $option)
  }
`;

const QUERY = gql`
  query warehouseList {
    warehouseList {
      id
      name
      lat
      lng
      canCustomerPickup
      canTransit
      canDeliveryPickup
      isHub
      description
      trafficLevel
      trafficMessage {
        en
        km
      }
    }
  }
`;

const MUTATE_UPDATE_WAREHOUSE_FLAG = gql`
  mutation updateWarehouse($id: Int!, $data: WarehouseInput!) {
    updateWarehouse(id: $id, data: $data)
  }
`;

function DeliveryHubFlag({
  hub,
  hubList,
  messageTemplates,
}: {
  hub: Graph.Warehouse;
  hubList?: Graph.Warehouse[];
  messageTemplates: MessageTemplateType[];
}) {
  const [update, { loading }] = useMutation(MUTATE_UPDATE_WAREHOUSE_FLAG, { refetchQueries: ['warehouseList'] });

  function flag(flag: Graph.Warehouse): Graph.Warehouse {
    return {
      canTransit: hub.canTransit,
      canCustomerPickup: hub.canCustomerPickup,
      canDeliveryPickup: hub.canDeliveryPickup,
      ...flag,
    };
  }
  return (
    <div>
      <Checkbox
        label="Allow Transit"
        checked={hub.canTransit || false}
        disabled={loading}
        onChange={e => update({ variables: { id: hub.id, data: flag({ canTransit: e.target.checked }) } })}
      />
      <Checkbox
        label="Customer Can Pickup"
        checked={hub.canCustomerPickup || false}
        disabled={loading}
        onChange={e => update({ variables: { id: hub.id, data: flag({ canCustomerPickup: e.target.checked }) } })}
      />
      <Checkbox
        label="Delivery Pickup"
        checked={hub.canDeliveryPickup || false}
        disabled={loading}
        onChange={e => update({ variables: { id: hub.id, data: flag({ canDeliveryPickup: e.target.checked }) } })}
      />
      <Checkbox
        label="Traffic Busy"
        checked={hub?.trafficLevel && hub?.trafficLevel > 0 ? true : false}
        disabled={loading}
        onChange={e => update({ variables: { id: hub.id, data: flag({ trafficLevel: !!e.target.checked ? 1 : 0 }) } })}
      />
      <TrafficMessageModal warehouse={hub} warehouseList={hubList} messageTemplates={messageTemplates} />
    </div>
  );
}

export function DeliveryHubScreen() {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const query = `${pathname}`;

  useEffect(() => {
    history.replace(query + '#hubs');
  }, [search, query, history]);

  const { data } = useQuery<Graph.Query>(QUERY);
  const warehouseList = data?.warehouseList || [];

  const { data: settingData } = useQuery<Graph.Query>(QUERY_SETTING, { variables: { option: 'MSG_TEMPLATE' } });
  const messageTemplates = settingData?.setting ? JSON.parse(settingData.setting) : [];

  // Computing the bound
  const width = 1100;
  const height = 800;
  const hubWidth = 200;
  const hubHeight = 175;

  const central = warehouseList.find(x => x.isHub);
  const centralIndex = warehouseList.findIndex(x => x.isHub);

  function calculateHubPosition(hub: Graph.Warehouse, idx: number) {
    const halfWidth = hubWidth / 2;
    const halfHeight = hubHeight / 2;
    let x;
    let y;
    const width2 = (width - hubWidth) / 2 - 30;
    const height2 = (height - hubHeight) / 2 - 30;

    if (hub.id === central?.id) {
      x = width / 2;
      y = height / 2;
    } else {
      const angle = (idx * (Math.PI * 2)) / (warehouseList.length - 1);
      x = width / 2 + Math.sin(angle) * width2;
      y = height / 2 + Math.cos(angle) * height2;
    }

    return { x, y, left: x - halfWidth, top: y - halfHeight };
  }

  const hubPositionList = warehouseList.map(calculateHubPosition);

  function drawHub(
    hub: Graph.Warehouse,
    idx: number,
    hubList?: Graph.Warehouse[],
    messageTemplates?: MessageTemplateType[],
  ) {
    return (
      <div
        key={hub.id?.toString() || ''}
        style={{
          background: '#fff',
          width: hubWidth,
          height: hubHeight,
          left: hubPositionList[idx].left,
          top: hubPositionList[idx].top,
          position: 'absolute',
          padding: 10,
          boxShadow: '0px 2px 4px rgba(126, 142, 177, 0.12)',
        }}
      >
        <div className="mb-2">
          <strong className="mb-1">{hub.name}</strong>
          {hub.isHub && <span>&nbsp;(Hub)</span>}
        </div>
        <DeliveryHubFlag hub={hub} hubList={hubList} messageTemplates={messageTemplates || []} />
      </div>
    );
  }

  return (
    <div>
      <div style={{ width, height, background: '#f1f1f1', position: 'relative' }}>
        <svg width={width} height={height}>
          {hubPositionList.map((hub, idx) => (
            <line
              key={idx}
              x1={hub.x}
              y1={hub.y}
              x2={hubPositionList[centralIndex].x}
              y2={hubPositionList[centralIndex].y}
              style={{ strokeWidth: 3, stroke: '#555' }}
            />
          ))}
        </svg>
        {warehouseList.map((hub, idx) => drawHub(hub, idx, warehouseList, messageTemplates))}
      </div>
    </div>
  );
}

interface MessageTemplateType {
  label: string;
  value: unknown;
}

function TrafficMessageModal({
  warehouse,
  warehouseList,
  messageTemplates,
}: {
  warehouse: Graph.Warehouse;
  warehouseList?: Graph.Warehouse[];
  messageTemplates: MessageTemplateType[];
}) {
  const [update, { loading }] = useMutation(MUTATE_UPDATE_WAREHOUSE_FLAG, { refetchQueries: ['warehouseList'] });
  const [active, setActive] = useState(false);
  const [message, setMessage] = useState({ en: '', km: '' });

  const handleChange = useCallback(() => setActive(!active), [active]);

  const activator = (
    <div
      className="flex flex-row items-center justify-between mt-2 hover:bg-gray-50 cursor-pointer"
      onClick={handleChange}
    >
      <div>Traffic Message</div>
      <div>
        <Icon source={ChevronRightMinor} />
      </div>
    </div>
  );

  const handleSave = useCallback(() => {
    update({
      variables: {
        id: warehouse.id,
        data: {
          canTransit: warehouse.canTransit,
          canCustomerPickup: warehouse.canCustomerPickup,
          canDeliveryPickup: warehouse.canDeliveryPickup,
          trafficMessage: message,
        },
      },
    });
  }, [message, warehouse]);

  const handleSaveAllhubs = useCallback(() => {
    for (const warehouse of warehouseList || []) {
      update({
        variables: {
          id: warehouse.id,
          data: {
            canTransit: warehouse.canTransit,
            canCustomerPickup: warehouse.canCustomerPickup,
            canDeliveryPickup: warehouse.canDeliveryPickup,
            trafficMessage: message,
          },
        },
      });
    }
  }, [message, warehouseList]);
  return (
    <Modal
      activator={activator}
      open={active}
      onClose={handleChange}
      title={'Busy traffic message for hub: ' + warehouse.name}
      primaryAction={{
        content: 'Save',
        onAction: handleSave,
        loading: loading,
      }}
      secondaryActions={[
        {
          content: 'Save to all hubs',
          onAction: handleSaveAllhubs,
        },
      ]}
    >
      <Modal.Section>
        <div>EN: {warehouse.trafficMessage?.en}</div>
        <div>KM: {warehouse.trafficMessage?.km}</div>
      </Modal.Section>
      <Modal.Section>
        <Select
          label="Select a template"
          options={(messageTemplates || []).map((msg: MessageTemplateType) => {
            return { label: msg.label, value: JSON.stringify(msg.value) };
          })}
          onChange={v => setMessage(JSON.parse(v))}
          value={JSON.stringify(message)}
        />

        <br></br>

        <div className="mb-2">Or type in your custom message below</div>
        <TextField
          autoComplete="off"
          label=""
          labelHidden
          prefix="English"
          value={message.en}
          onChange={v => setMessage({ ...message, en: v })}
        />
        <br></br>
        <TextField
          autoComplete="off"
          label=""
          labelHidden
          prefix="Khmer"
          value={message.km}
          onChange={v => setMessage({ ...message, km: v })}
        />
      </Modal.Section>
    </Modal>
  );
}
