import React, { useState } from 'react';
import styles from './styles.module.scss';
import PolarisNavigation from '../PolarisNavigation';

export function OverLayNavigation() {
  const [hover, setHover] = useState(false);
  return (
    <div className={styles['overlay-contain']} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div
        style={{
          height: 56,
          padding: '10px 16px',
          display: 'flex',
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          backgroundColor: 'transparent',
          alignItems: 'center',
          gap: 8,
          width: 124,
        }}
      >
        <a href="/">
          <img alt="L192" src="/logo_l192.png" className="Polaris-TopBar__Logo" style={{ width: 32 }} />
        </a>
        {/* <div
          className="text-white"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img
            src={open ? "/assets/icons8-double-left-52.png" : "/assets/icons8-double-right-52.png"}
            style={{ width: 17 }}
            alt=""
          />
        </div> */}
      </div>
      <div className={styles.main}>{!!hover && <PolarisNavigation />}</div>
    </div>
  );
}
