/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigation } from '@shopify/polaris';
import { useLocation } from 'react-router';
import { useUser } from 'src/contexts/AuthProvider';
import { navigationForAll } from './NavigationSetting/navigationForAll';
import { navigationForOffline } from './NavigationSetting/navigationForOffline';
import { useWindowSize } from 'src/hooks/useWindowResize';
import styles from './styles.module.scss';
import { useToggle } from 'src/contexts/ToggleProvider';

export function MenuItemIcon({ icon }: { icon: string }) {
  return (
    <div style={{ width: 20, height: 20, lineHeight: '20px', marginRight: '0.75rem' }}>
      <i className={`${icon} ml-1`} />
    </div>
  );
}

export default function PolarisNavigation() {
  const user = useUser();
  const location = useLocation();
  const [width] = useWindowSize();
  const { open, setOpen } = useToggle();

  let navigationItems = navigationForAll(user.superAdmin || false, user.groupID || 0);
  if (user.groupID === 6 || user.groupID === 8) {
    navigationItems = navigationForOffline();
  }

  return (
    <div style={{ backgroundColor: 'rgba(235, 235, 235, 1' }}>
      {(width || 0) > 770 ? (
        <div
          className={styles['double-toggle']}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img
            src={open ? '/assets/icons8-double-left-52-dark.png' : '/assets/icons8-double-right-52-dark.png'}
            style={{ width: 17 }}
            alt=""
          />
        </div>
      ) : (
        undefined
      )}
      <div style={open ? { overflowY: 'auto', maxHeight: '91vh', overflowX: 'hidden' } : {}}>
        <Navigation location={location.pathname}>
          {navigationItems.map((menu, index) => (
            <Navigation.Section
              separator={index > 0}
              key={menu.title}
              title={menu.title}
              items={menu.items.map(x => {
                return {
                  label: (
                    <div style={{ display: 'flex' }}>
                      {x.icon && <MenuItemIcon icon={x.icon} />}
                      {x.label}
                    </div>
                  ) as any,
                  url: x.url,
                  selected: x.url === location.pathname,
                  exactMatch: true,
                  badge: (x as any).badge ? (x as any).badge : undefined,
                  subNavigationItems: (x as any).subs ? (x as any).subs : undefined,
                };
              })}
            />
          ))}
        </Navigation>
      </div>
    </div>
  );
}
