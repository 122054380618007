/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Graph } from '../../generated/graph';
import { MenuRowFieldMaker } from './components/MenuRowFieldMaker';
import {
  Avatar,
  Badge,
  Divider,
  EmptyState,
  IndexTable,
  Layout,
  MenuGroupDescriptor,
  Page,
  Text,
  Thumbnail,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { Progress, Status } from '@shopify/polaris/build/ts/latest/src/components/Badge';
import style from './components/style.module.scss';
import Formatter from 'src/libs/Formatter';
import { PolarisCard } from 'src/components/polaris/PolarisCard';
import { FilterProduct } from './components/Filter/FilterProduct';
import {
  ProductListArguments,
  useLazyQueryProductList,
  useQueryProductList,
} from './components/Filter/useQueryProductList';
import { FiltersForm } from './components/Filter/FiltersForm';
import PolarisPagination from 'src/components/polaris/PolarisPagination';
import { DisplayProductMedia } from './components/DisplayProductMedia';
import { actionGroupDownload } from './components/actionGroup/ActionGroupDownload';
import { useHistory } from 'react-router';
import { BulkAction } from '@shopify/polaris/build/ts/latest/src/components/BulkActions';
import { MassDiscountPopup } from './components/popup/MassDiscountPopup';
import { MassPricePopup } from './components/popup/MassPricePopup';
import { MassReturnable } from './components/popup/MassReturnable';
import { RenewPublish } from './components/popup/RenewPublish';
import { Link } from 'react-router-dom';
import { RatingProduct } from './components/RatingProduct';
import { CancelMinor } from '@shopify/polaris-icons';
import { useSearchImageProduct } from 'src/contexts/SearchImageProductProvider';

function renderRestockTableRow(
  item: Graph.ProductV2,
  index: number,
  selectedResources: any,
  offset?: number,
  limit?: number,
): ReactElement {
  const query = new URLSearchParams(window.location.search);
  const discount = Number(item.price) * (Number(item.discount) / 100);

  const status: {
    progress: Progress;
    status: Status;
  } = {
    progress: 'incomplete',
    status: 'info',
  };

  if (item.mark === 'Available') {
    status.progress = 'complete';
    status.status = 'success';
  }

  if (item.mark === 'Discontinue') {
    status.progress = 'partiallyComplete';
    status.status = 'attention';
  }

  if (item.mark === 'Out of stock') {
    status.progress = 'partiallyComplete';
    status.status = 'warning';
  }

  if (item.mark === 'Discount Offer') {
    status.status = 'success';
  }

  if (item.mark === 'Not Approved') {
    status.status = 'critical';
  }

  const position = (offset || 0) * (limit || 0);

  return (
    <IndexTable.Row
      id={String(position + index) || '0'}
      position={index}
      selected={selectedResources.includes(String(position + index))}
      key={String(item.id) || '0'}
      onClick={() => {
        //--code here
      }}
    >
      <IndexTable.Cell flush className={`text-center ${style.border_right_width}`}>
        <a
          href="#"
          onClick={() => {
            window.open(`/product/edit/${item.id}`, '_blank');
            window.focus();
          }}
        >
          {index + 1 + Number(query.get('page') || '0') * 50}
        </a>
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width}`}>
        <small className="text-success">
          <b style={{ fontWeight: 700 }}>
            {(item as any).isCore ? (
              <>
                <span className="os-icon os-icon-ui-21"></span> Core Product
              </>
            ) : (
              ''
            )}
          </b>{' '}
          <br />
        </small>
        {item.picture && <DisplayProductMedia id={item.id || 0} src={item.picture} />}
        <a
          onClick={() => {
            window.open(`https://www.l192.com/product/${item.id}`, '_blank');
          }}
          href={`https://www.l192.com/product/${item.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <small>{item.code}</small>
        </a>
        <br />
        {item.srcProductLink && (
          <div>
            <a
              onClick={() => {
                window.open(item.srcProductLink || '', '_blank');
              }}
              href={item.srcProductLink || ''}
              target="_blank"
              rel="noreferrer"
            >
              <small>Store Product</small>
            </a>
          </div>
        )}
        {item.srcStoreLink && (
          <div>
            <a
              onClick={() => {
                window.open(item.srcStoreLink || '', '_blank');
              }}
              href={item.srcStoreLink || ''}
              target="_blank"
              rel="noreferrer"
            >
              <small>Store Link</small>
            </a>
          </div>
        )}
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width}`}>
        <div style={{ position: 'relative' }}>
          <Tooltip
            preferredPosition="above"
            borderRadius="2"
            width="wide"
            activatorWrapper="space"
            content={
              <div>
                {item.title?.km && (
                  <Text as="p" variant="bodySm" color="subdued">
                    <strong className="p-2">{item.title?.km}</strong>
                    <br />
                    <br />
                    <Divider />
                    <br />
                  </Text>
                )}
                {item.title?.en && (
                  <Text as="p" variant="bodySm" color="subdued">
                    <strong>{item.title?.en}</strong>
                    <br />
                    <br />
                    <Divider />
                    <br />
                  </Text>
                )}
                <Text as="p" variant="bodySm" color="subdued">
                  <strong className="p-2">{item.title?.zh}</strong>
                </Text>
              </div>
            }
          >
            <div style={{ maxWidth: '18rem' }}>
              <div className="mb-2">
                <Text as="p" variant="bodySm" truncate color="subdued">
                  KM: {item.title?.km}
                </Text>
              </div>
              <div className="mb-2">
                <Text as="p" variant="bodySm" truncate color="subdued">
                  EN: {item.title?.en}
                </Text>
              </div>
              <div className="mb-3">
                <Text as="p" variant="bodySm" truncate color="subdued">
                  ZH: {item.title?.zh}
                </Text>
              </div>
              <div>
                <Text as="p" variant="bodySm" truncate color="subdued">
                  Origin:{' '}
                  <Badge status={item.supplier?.origin === 'Local' ? 'new' : 'attention'}>
                    {(<small>{item.supplier?.origin || ''}</small>) as any}
                  </Badge>
                </Text>
              </div>
              <div className="text-left mt-2">
                <Link
                  onClick={() => {
                    const win = window.open(`/marketing/watermark?code=${item.code}`, '_blank');
                    win?.focus();
                  }}
                  to={`#`}
                >
                  <small>Watermark</small>
                </Link>
              </div>
            </div>
          </Tooltip>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width}`}>
        {item.supplier && (
          <div className="mb-2">
            <Text as="p" variant="bodySm">
              Supplier:{' '}
              <Text as="span" variant="bodySm" color="subdued">
                {item.supplier.name} {item.supplier.isSeller ? '(Seller)' : ''}
              </Text>
            </Text>
          </div>
        )}
        {item.brand && (
          <div className="mb-2">
            <Text as="p" variant="bodySm">
              Brand:{' '}
              <Text as="span" variant="bodySm" color="success">
                {item.brand.name}
              </Text>
            </Text>
          </div>
        )}
        {item.category && (
          <div className="mb-2">
            <Text as="p" variant="bodySm">
              Category:{' '}
              <Text as="span" variant="bodySm" color="subdued">
                {item.category.name?.en}
              </Text>
            </Text>
          </div>
        )}
        {item.vendor && (
          <div className="mb-2">
            <Text as="p" variant="bodySm">
              Vendor:{' '}
              <Text as="span" variant="bodySm" color="subdued">
                {item.vendor.name}
              </Text>
            </Text>
          </div>
        )}
        <div className="mb-2">
          <div className={style.tooltip}>
            <Text as="p" variant="bodySm" truncate color="subdued">
              <Badge status={'new'}>{(<small>SKU: {item.skuList?.length || ''}</small>) as any}</Badge>
            </Text>
            <div className={style.tooltiptext}>
              <table>
                <thead>
                  <tr>
                    <th className={`${style.border_right_width}`} style={{ borderBottomWidth: '0.0625rem' }}>
                      <small>
                        <b>Barcode</b>
                      </small>
                    </th>
                    <th className={`${style.border_right_width}`} style={{ borderBottomWidth: '0.0625rem' }}>
                      <small>
                        <b>Color</b>
                      </small>
                    </th>
                    <th className={`${style.border_right_width}`} style={{ borderBottomWidth: '0.0625rem' }}>
                      <small>
                        <b>Size</b>
                      </small>
                    </th>
                    <th className={`${style.border_right_width}`} style={{ borderBottomWidth: '0.0625rem' }}>
                      <small>
                        <b>Enabled</b>
                      </small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item.skuList?.map(x => {
                    return (
                      <tr key={x.id}>
                        <td className={`${style.border_right_width}`} style={{ borderBottomWidth: '0.0625rem' }}>
                          <small>{x.barcode}</small>
                        </td>
                        <td className={`${style.border_right_width}`} style={{ borderBottomWidth: '0.0625rem' }}>
                          <small>{x.option1}</small>
                        </td>
                        <td className={`${style.border_right_width}`} style={{ borderBottomWidth: '0.0625rem' }}>
                          <small>{x.option2}</small>
                        </td>
                        <td className={`${style.border_right_width}`} style={{ borderBottomWidth: '0.0625rem' }}>
                          <small>{x.enabled ? 'Yes' : 'No'}</small>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <RatingProduct item={item} />
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width}`}>
        <div className="mb-2">
          <Badge
            progress={item.status === 'Active' ? 'complete' : 'incomplete'}
            status={item.status === 'Active' ? 'success' : 'critical'}
          >
            {item.status || ''}
          </Badge>
        </div>
        <Badge progress={status.progress} status={status.status}>
          {item.mark || ''}
        </Badge>
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width}`}>
        <Text as="p" variant="bodySm" color="subdued">
          {(item as any).weight} gram
        </Text>
        <br></br>
        <Text as="p" variant="bodySm" color="subdued">
          {item.boxSize}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width} ${style.table_cell_top}`}>
        <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
          <Text as="p" variant="bodySm">
            <div className={`${style.text_inline} p-2`} style={{ justifyContent: 'space-between' }}>
              <div style={{ width: 100 }}>Avg Cost :</div>
              <div>${Number((item as any).avgCost).toFixed(2)}</div>
            </div>
          </Text>
          <Divider />
        </div>
        <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
          <Text as="p" variant="bodySm">
            <div className={`${style.text_inline} pb-2 pl-2 pr-2`} style={{ justifyContent: 'space-between' }}>
              <div style={{ width: 100 }}>Sale Price :</div>
              <div>
                <span>
                  <span
                    style={discount > 0 ? { textDecoration: 'line-through', color: '#e65252', marginRight: 5 } : {}}
                  >
                    ${Number((item as any).price || 0).toFixed(2)}
                  </span>
                  {discount > 0 && <span>${(Number((item as any).price || 0) - discount).toFixed(2)}</span>}
                </span>
              </div>
            </div>
          </Text>
          <Divider />
        </div>
        <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
          <Text as="p" variant="bodySm" color="success">
            <div className={`${style.text_inline} pb-2 pl-2 pr-2`} style={{ justifyContent: 'space-between' }}>
              <div style={{ width: 100 }}>Discount :</div>
              <div>
                <Badge status="success">{(<small>{`${(item as any).discount}%`}</small>) as any}</Badge>
              </div>
            </div>
          </Text>
          <Divider />
        </div>
        <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
          <Text as="p" variant="bodySm" color="warning">
            <div className={`${style.text_inline} pb-2 pl-2 pr-2`} style={{ justifyContent: 'space-between' }}>
              <div style={{ width: 100 }}>Gold :</div>
              <Badge status="warning">{(<small>{`${item.discountGold}%`}</small>) as any}</Badge>
            </div>
          </Text>
          <Divider />
        </div>
        <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
          <Text as="p" variant="bodySm" color="critical">
            <div className={`${style.text_inline} pb-2 pl-2 pr-2`} style={{ justifyContent: 'space-between' }}>
              <div style={{ width: 100 }}>Max :</div>
              <Badge status="critical">{(<small>{(item as any).discountMax}%</small>) as any}</Badge>
            </div>
          </Text>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width} ${style.table_cell_top}`}>
        <div>
          <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
            <Text as="p" variant="bodySm" color="subdued">
              <div className={`${style.text_inline} p-2`} style={{ justifyContent: 'space-between' }}>
                <div style={{ width: 100 }}>Reach :</div>
                <div>{(<strong>{Formatter.abbrNum(item.reach || 0)}</strong>) as any}</div>
              </div>
            </Text>
            <Divider />
          </div>
          <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
            <Text as="p" variant="bodySm" color="subdued">
              <div className={`${style.text_inline} pb-2 pl-2 pr-2`} style={{ justifyContent: 'space-between' }}>
                <div style={{ width: 100 }}>Click :</div>
                <div>{(<strong>{Formatter.abbrNum(item.clicks || 0)}</strong>) as any}</div>
              </div>
            </Text>
            <Divider />
          </div>
          <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
            <Text as="p" variant="bodySm" color="subdued">
              <div
                className={`${style.text_inline} pb-2 pl-2 pr-2`}
                style={{ justifyContent: 'space-between', marginBottom: 5 }}
              >
                <div style={{ width: 100 }}>Revenue :</div>
                <div>{(<strong>{Formatter.abbrNum(item.revenue || 0)}</strong>) as any}</div>
              </div>
            </Text>
            <Divider />
          </div>
          <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
            <Text as="p" variant="bodySm" color="subdued">
              <div
                className={`${style.text_inline} pb-2 pl-2 pr-2`}
                style={{ justifyContent: 'space-between', marginBottom: 5 }}
              >
                <div style={{ width: 100 }}>Sale :</div>
                <div>{(<strong>{Formatter.abbrNum(Number(item.salesInFilter || 0))}</strong>) as any}</div>
              </div>
            </Text>
            <Divider />
          </div>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width} ${style.table_cell_top}`}>
        <div>
          <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
            <Text as="p" variant="bodySm" color="subdued">
              <div className={`${style.text_inline} p-2`} style={{ justifyContent: 'space-between' }}>
                <div style={{ width: 100 }}>Sold Qty :</div>
                <div>{(<strong>{item.soldQty || 0}</strong>) as any}</div>
              </div>
            </Text>
            <Divider />
          </div>
          <div className="mb-2" style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
            <Text as="p" variant="bodySm" color="subdued">
              <div className={`${style.text_inline} pb-2 pl-2 pr-2`} style={{ justifyContent: 'space-between' }}>
                <div style={{ width: 100 }}>Stock Qty :</div>
                <div>{(<strong>{item.stockQty || 0}</strong>) as any}</div>
              </div>
            </Text>
            <Divider />
          </div>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width}`}>
        {item.selectedBy && (
          <div className="mb-2" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="mr-1">
              <Avatar
                source={item.selectedBy.image || ''}
                size="small"
                shape="round"
                initials={item.selectedBy.name
                  ?.split(' ')
                  .map(x => x.charAt(0).toUpperCase())
                  .join('')}
              />
            </div>
            <Text as="p" variant="bodySm">
              <Text as="span" variant="bodySm" color="subdued">
                {item.selectedBy.name}
              </Text>
            </Text>
          </div>
        )}
      </IndexTable.Cell>
      <IndexTable.Cell flush className={`${style.border_right_width}`}>
        <MenuRowFieldMaker item={item} />
      </IndexTable.Cell>
    </IndexTable.Row>
  );
}

function renderRestockTable(
  items: Graph.Product[],
  offset: number,
  limit: number,
  { selectedResources, allResourcesSelected, handleSelectionChange }: any,
  loading?: boolean,
  total?: number,
  filterValue?: any,
  selectable?: boolean,
): ReactElement {
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const [openDiscount, setOpenDiscount] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openReturnable, setOpenReturnable] = useState(false);
  const [openRenew, setOpenRenew] = useState(false);
  const [id, setIds] = useState<number[]>([]);

  const [productQuery, { loading: loading2 }] = useLazyQueryProductList({
    variables: {
      ...filterValue,
      filter:
        JSON.stringify(filterValue.filter?.productScore) === '{}'
          ? {
              ...filterValue.filter,
              categories: filterValue.filter?.categories?.map((x: any) => Number(x.toString().replace('s', ''))),
              productScore: undefined,
            }
          : {
              ...filterValue.filter,
              categories: filterValue.filter?.categories?.map((x: any) => Number(x.toString().replace('s', ''))),
            },
      page: {
        limit: selectedResources.length,
        offset: 0,
      },
    },
    type: 'ID',
  });

  useEffect(() => {
    if (!!selectable) {
      setIds([]);
    }
  }, [selectable]);

  const promotedBulkActions: (BulkAction | MenuGroupDescriptor)[] | undefined = [
    ...actionGroupDownload(filterValue, offset, limit, selectedResources, total || 0),
    { content: 'Renew Publish', onAction: () => setOpenRenew(true) },
    {
      content: 'Returnable',
      onAction: () => setOpenReturnable(true),
    },
    {
      content: 'Discount',
      onAction: () => {
        setOpenDiscount(true);
      },
    },
    {
      content: 'Price',
      onAction: () => {
        setOpenPrice(true);
      },
    },
    {
      content: 'Facebook Post',
      disabled: (selectedResources as string[]).length > 200 || loading2,
      onAction: () => {
        if (total === selectedResources.length) {
          productQuery().then(res => {
            window.open(`/media/facebook_post?id=${res.data?.productListV2?.data?.map(x => x.id).join(',')}`, '_blank');
          });
        } else {
          window.open(`/media/facebook_post?id=${id.join(',')}`, '_blank');
        }
      },
    },
  ];

  return (
    <React.Fragment>
      <MassDiscountPopup
        itemIds={id.length === 0 ? selectedResources : id.map((x: any) => Number(x))}
        open={openDiscount}
        onClose={setOpenDiscount}
        total={total ? total : 50}
        filterValue={filterValue}
      />
      <MassPricePopup
        total={total ? total : 50}
        filterValue={filterValue}
        itemIds={id.length === 0 ? selectedResources : id.map((x: any) => Number(x))}
        open={openPrice}
        onClose={setOpenPrice}
      />
      <MassReturnable
        filterValue={filterValue}
        itemIds={id.length === 0 ? selectedResources : id.map((x: any) => Number(x))}
        open={openReturnable}
        onClose={setOpenReturnable}
        total={total ? total : 50}
      />
      <RenewPublish
        filterValue={filterValue}
        itemIds={id.length === 0 ? selectedResources : id.map((x: any) => Number(x))}
        open={openRenew}
        onClose={setOpenRenew}
        total={total ? total : 50}
      />
      <IndexTable
        headings={[
          { title: 'No.' },
          { title: 'Item' },
          { title: 'Title' },
          { title: 'Info' },
          { title: 'Status/Mark' },
          { title: 'Weight & Size' },
          { title: 'Price' },
          { title: 'Performance' },
          { title: 'Qty' },
          { title: 'Selected By' },
          { title: '' },
        ]}
        itemCount={total || 0}
        selectable={true}
        lastColumnSticky
        loading={loading}
        selectedItemsCount={allResourcesSelected ? 'All' : (selectedResources || []).length}
        paginatedSelectAllActionText={`${(selectedResources || []).length} selected`}
        onSelectionChange={async (t, i, s) => {
          handleSelectionChange(t, i, s);
          await sleep(1);
          if (t === 'single') {
            const index = Number(s) - Number(limit) * Number(offset);
            const find = items[index];
            if (find) {
              if (!!i) {
                setIds([...id, find.id || 0]);
              } else {
                setIds(id.filter(x => x !== find.id));
              }
            }
          } else {
            setIds([]);
          }
          await sleep(1);
        }}
        promotedBulkActions={promotedBulkActions}
        emptyState={
          <EmptyState
            heading="Products not found"
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          >
            <p>Try changing the filters or select seller</p>
          </EmptyState>
        }
      >
        {items.map((item, index) => renderRestockTableRow(item, index, selectedResources, offset, limit))}
      </IndexTable>
    </React.Fragment>
  );
}

function productListBody(
  data: Graph.ProductV2[],
  offset: number,
  limit: number,
  resourceState?: any,
  loading?: boolean,
  total?: number,
  filterValue?: any,
  selectable?: boolean,
): ReactElement {
  return (
    <span>{renderRestockTable(data || [], offset, limit, resourceState, loading, total, filterValue, selectable)}</span>
  );
}

export function ProductListScreen(): ReactElement {
  const { location, replace } = useHistory();
  const query = new URLSearchParams(location.search);
  const [searchInput, setSearchInput] = useState('');
  const [total, setTotal] = useState(0);
  const [loadingTab, setLoadingTab] = useState(false);
  const [offset, setOffset] = useState(Number(query.get('page') || 0));
  const productCode = query.get('code') || '';
  const { file, setFile, setHash, setProductIds, productIds, hash } = useSearchImageProduct();
  const [reloading, setReLoading] = useState(false);

  const [filterValue, setFilterValue] = useState<ProductListArguments>({
    filter: {},
    orderBy: {
      field: 'DATE',
      sortBy: 'DESC',
    },
  });

  const { filters, appliedFilters } = FiltersForm({
    filters: filterValue,
    setFilters: v => {
      setLoadingTab(true);
      setFilterValue(v);
      setTotal(0);
      setTimeout(() => {
        setLoadingTab(false);
      }, 300);
    },
  });

  const { data, loading } = useQueryProductList({
    skip: !filterValue.filter?.supplierId && !filterValue.search,
    variables: {
      ...filterValue,
      filter:
        JSON.stringify(filterValue.filter?.productScore) === '{}'
          ? {
              ...filterValue.filter,
              categories: filterValue.filter?.categories?.map(x => Number(x.toString().replace('s', ''))),
              productScore: undefined,
            }
          : {
              ...filterValue.filter,
              categories: filterValue.filter?.categories?.map(x => Number(x.toString().replace('s', ''))),
            },
      page: {
        limit: 50,
        offset: offset * 50,
        cacheTotalRow: total,
      },
    },
    onCompleted: res => {
      setReLoading(false);
      setTotal(res.productListV2?.pagination?.total || 0);
    },
  });

  useEffect(() => {
    return () => {
      setFile(null);
      setHash(null);
      setProductIds([]);
    };
  }, []);

  const handleQueryChange = useCallback(
    v => {
      setSearchInput(v);

      if (v === '') {
        setFilterValue({
          ...filterValue,
          search: undefined,
        });
      }
    },
    [filterValue, searchInput],
  );

  const handleQueryEnter = useCallback(() => {
    if (!!searchInput) {
      setFilterValue({
        ...filterValue,
        search: { code: searchInput.trim() },
      });
    }
  }, [searchInput, filterValue]);

  const handleQueryClear = useCallback(() => {
    setSearchInput('');
    setFilterValue({
      ...filterValue,
      search: undefined,
    });
  }, [filterValue, searchInput]);

  useEffect(() => {
    setSearchInput(productCode);
    if (!!productCode) {
      setFilterValue({
        ...filterValue,
        search: { code: productCode.trim() },
      });
    }
  }, [productCode]);

  const handleClearAll = useCallback(() => {
    setFilterValue({
      filter: {},
      orderBy: {
        field: 'DATE',
        sortBy: 'DESC',
      },
    });
    setHash(null);
    setFile(undefined);
    setProductIds([]);
  }, []);

  const handleSort = useCallback(
    v => {
      setFilterValue({
        ...filterValue,
        orderBy: {
          field: String(v)
            .split(' ')[0]
            .toUpperCase() as Graph.ProductOrderBy,
          sortBy: String(v)
            .split(' ')[1]
            .toUpperCase() as Graph.SortOrder,
        },
      });
    },
    [filterValue],
  );

  const handleSelectedTab = useCallback(key => {
    setLoadingTab(true);
    const storageFilters = localStorage.getItem('filtersProduct')
      ? JSON.parse(localStorage.getItem('filtersProduct') || '{}')
      : {};

    setOffset(0);
    clearSelection();
    setTotal(0);

    if (storageFilters[key]) {
      setFilterValue({
        ...storageFilters[key],
      });
    } else {
      setFilterValue({
        filter: {},
        orderBy: {
          field: 'DATE',
          sortBy: 'DESC',
        },
      });
    }

    setTimeout(() => {
      setLoadingTab(false);
    }, 500);
  }, []);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(
    [...new Array(data ? data.productListV2?.pagination?.total || 0 : 0)].map((x, i) => {
      return {
        id: String(i),
        key: String(i),
      };
    }),
  );

  const handleNext = useCallback(() => {
    query.set('page', (offset + 1).toString());
    replace({
      pathname: location.pathname,
      search: query.toString(),
    });
    setOffset(offset + 1);
  }, [offset, selectedResources]);

  const handlePrevious = useCallback(() => {
    query.set('page', (offset - 1).toString());
    replace({
      pathname: location.pathname,
      search: query.toString(),
    });
    setOffset(offset - 1);
  }, [offset, selectedResources]);

  const labelPage = `${data?.productListV2?.pagination?.current || 1}-${(data?.productListV2?.pagination?.size || 0) +
    (data?.productListV2?.pagination?.current || 0)} of ${data?.productListV2?.pagination?.total || 0} Products`;

  let list = data ? data.productListV2?.data || [] : [];

  if (!reloading) {
    if (hash && !loading && productIds.length > 0 && data) {
      list = [];
      for (const x of productIds) {
        const find = (data.productListV2?.data as any).find((f: any) => f.id === x);
        if (find) {
          list.push(find);
        }
      }
    } else {
      list = !loading && data ? data.productListV2?.data || [] : [];
    }
  }

  return (
    <Page
      fullWidth={true}
      actionGroups={actionGroupDownload(
        filterValue,
        offset,
        50,
        selectedResources,
        data?.productListV2?.pagination?.total || 0,
      )}
      title={
        file
          ? ((
              <div className="relative max-w-20">
                <Thumbnail alt="" source={window.URL.createObjectURL(file)} size="medium" />
                <div
                  onClick={() => {
                    handleQueryClear();
                    setFile(undefined);
                    setHash(null);
                    setProductIds([]);
                  }}
                  className="cursor-pointer absolute bg-red-600 top-0 right-[20%] shadow-sm rounded-full border-collapse border-red-600 border-[0.5px] border-solid"
                >
                  <CancelMinor width={15} height={15} fill="#ffffff" />
                </div>
              </div>
            ) as any)
          : ''
      }
      // titleHidden={!fileInput}
    >
      <Layout>
        <Layout.Section>
          <PolarisCard>
            <PolarisCard.Section flush>
              <FilterProduct
                queryValue={searchInput}
                filters={filters}
                filterValue={filterValue}
                appliedFilters={appliedFilters}
                handleQueryChange={handleQueryChange}
                handleQueryEnter={handleQueryEnter}
                handleQueryClear={handleQueryClear}
                handleClearAll={handleClearAll}
                handleSort={handleSort}
                handleSelectedTab={handleSelectedTab}
                handleImageSearchResult={value => {
                  setReLoading(true);
                  setFilterValue({
                    filter: {},
                    orderBy: {
                      field: 'DATE',
                      sortBy: 'DESC',
                    },
                    search: {
                      ids: value,
                    },
                  });
                }}
                loading={loading}
              />
            </PolarisCard.Section>
            <PolarisCard.Section flush>
              <PolarisPagination
                type="table"
                label={labelPage}
                hasNext={data?.productListV2?.data?.length === (data?.productListV2?.pagination?.size || 0)}
                hasPrevious={offset > 0}
                onNext={handleNext}
                onPrevious={handlePrevious}
              />
            </PolarisCard.Section>
            <PolarisCard.Section flush>
              {productListBody(
                !!reloading ? (data ? data.productListV2?.data || [] : []) : list,
                offset,
                50,
                {
                  selectedResources,
                  allResourcesSelected,
                  handleSelectionChange,
                },
                loading,
                Number(data?.productListV2?.pagination?.total || 0),
                filterValue,
                loadingTab,
              )}
            </PolarisCard.Section>
            {/* <PolarisCard.Section flush>
              <PolarisPagination
                type="table"
                label={labelPage}
                hasNext={(data?.productListV2?.data?.length || 0) >= (filterValue.page?.limit || 0)}
                hasPrevious={offset > 0}
                onNext={handleNext}
                onPrevious={handlePrevious}
              />
            </PolarisCard.Section> */}
          </PolarisCard>
          <br />
          <div style={{ padding: 10, backgroundColor: '#ffffff' }}></div>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
