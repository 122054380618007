/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import Resizer from 'react-image-file-resizer';

export async function Base64ToFile(dataurl: any) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], new Date().getTime() + '.jpg', { type: mime });
}

export async function getResizer(file: any) {
  const res = new Promise(r => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result + '';
      image.onload = async () => {
        const size = file.size / 1000;
        const qty = Math.round((300 * 100) / size);

        if (image.width < 500) {
          const x = await Base64ToFile(reader.result);
          r(x);
        }

        if (image.width >= 500) {
          Resizer.imageFileResizer(
            file,
            image.width,
            image.height,
            'JPEG',
            size >= 1000 ? qty : 100,
            0,
            async uri => {
              const x = await Base64ToFile(uri);
              r(x);
            },
            'base64',
          );
        }
      };
    };
  });

  return res;
}
