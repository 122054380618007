/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Graph } from 'src/generated/graph';

export const QUERYPRODUCTLISTV2 = gql`
  query productListV2(
    $productListType: ProductListType!
    $page: PaginationInput
    $orderBy: ProductOrderByInput
    $search: ProductSearch
    $filter: ProductListFilterV2
  ) {
    productListV2(productListType: $productListType, page: $page, orderBy: $orderBy, search: $search, filter: $filter) {
      data {
        id
        picture
        code
        supplierCode
        title {
          en
          km
          zh
        }
        remoted
        mark
        status
        category {
          id
          name {
            km
            zh
            en
          }
        }
        brand {
          id
          name
        }
        vendor {
          id
          name
        }
        supplier {
          id
          name
          isSeller
          origin
        }
        isCore
        isRestock
        avgCost
        srcProductLink
        srcStoreLink
        selectedBy {
          id
          name
          image
        }
        price
        discount
        discountMax
        discountGold
        discountApp
        color
        size
        skuList {
          id
          option1
          option2
          stockQty
          enabled
          supplierStock
          barcode
        }
        firstSold
        lastSold
        soldQty
        mostSoldPerMonth
        mostSoldMonth
        transactionCount
        returnedRate
        uncollectedRate
        discountRate
        revenue
        addToCart
        reach
        clicks
        stockQty
        weight
        boxSize
        inputDate
        lastStockIn
        storeSourceLink
        productSourceLink
        salesInFilter
        productReview {
          rating
          reviews
        }
      }
      pagination {
        total
        size
        current
      }
    }
  }
`;

export const QUERYPRODUCTLISTV2ID = gql`
  query productListV2(
    $productListType: ProductListType!
    $page: PaginationInput
    $orderBy: ProductOrderByInput
    $search: ProductSearch
    $filter: ProductListFilterV2
  ) {
    productListV2(productListType: $productListType, page: $page, orderBy: $orderBy, search: $search, filter: $filter) {
      data {
        id
      }
    }
  }
`;

export const QUERYPRODUCTLISTV2EXCEL = gql`
  query productListV2(
    $productListType: ProductListType!
    $page: PaginationInput
    $orderBy: ProductOrderByInput
    $search: ProductSearch
    $filter: ProductListFilterV2
  ) {
    productListV2(productListType: $productListType, page: $page, orderBy: $orderBy, search: $search, filter: $filter) {
      data {
        id
        code
        supplier {
          name
        }
        brand {
          name
        }
        category {
          name {
            en
          }
        }
        mark
        weight
        price
        discount
        stockQty
      }
    }
  }
`;

export interface ProductListArguments {
  search?: Graph.ProductSearch;
  filter?: Graph.ProductListFilterV2;
  orderBy?: Graph.ProductOrderByInput;
  page?: Graph.PaginationInput;
  supplierFilter?: any;
}

export function useQueryProductList({
  variables,
  skip,
  idOnly,
  onCompleted,
}: {
  variables: ProductListArguments;
  skip?: boolean;
  idOnly?: boolean;
  onCompleted?: (data: Graph.Query) => void;
}) {
  return useQuery<Graph.Query>(idOnly ? QUERYPRODUCTLISTV2ID : QUERYPRODUCTLISTV2, {
    fetchPolicy: 'network-only',
    skip: !!skip,
    variables: {
      productListType: 'APPROVED',
      search: variables.search?.code || variables.search?.ids ? variables.search : null,
      filter: variables.filter,
      orderBy: variables.orderBy ? variables.orderBy : { field: 'DATE', sortBy: 'DESC' },
      page: variables.page
        ? variables.page
        : {
            limit: 20,
            offset: 0,
          },
    },
    onCompleted: onCompleted,
    notifyOnNetworkStatusChange: true,
  });
}

export function useLazyQueryProducts({
  variables,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  skip,
  idOnly,
  onCompleted,
}: {
  variables: ProductListArguments;
  skip?: boolean;
  idOnly?: boolean;
  onCompleted?: (data: Graph.Query) => void;
}) {
  return useLazyQuery<Graph.Query>(idOnly ? QUERYPRODUCTLISTV2ID : QUERYPRODUCTLISTV2, {
    fetchPolicy: 'network-only',
    variables: {
      productListType: 'APPROVED',
      search: variables.search?.code ? variables.search : null,
      filter: variables.filter,
      orderBy: variables.orderBy ? variables.orderBy : { field: 'DATE', sortBy: 'DESC' },
      page: variables.page
        ? variables.page
        : {
            limit: 20,
            offset: 0,
          },
    },
    onCompleted: onCompleted,
  });
}

export function useLazyQueryProductList({
  variables,
  type,
}: {
  variables: ProductListArguments;
  type: 'EXCEL' | 'ID' | undefined;
}) {
  const query = type === 'ID' ? QUERYPRODUCTLISTV2ID : type === 'EXCEL' ? QUERYPRODUCTLISTV2EXCEL : QUERYPRODUCTLISTV2;

  return useLazyQuery<Graph.Query>(query, {
    fetchPolicy: 'no-cache',
    variables: {
      productListType: 'APPROVED',
      search: variables.search?.code ? variables.search : null,
      filter: variables.filter,
      orderBy: variables.orderBy ? variables.orderBy : { field: 'DATE', sortBy: 'DESC' },
      page: variables.page
        ? variables.page
        : {
            limit: 20,
            offset: 0,
          },
    },
  });
}
