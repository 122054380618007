/* eslint-disable */
import React from 'react'

const GeneratedRoute = [
  { path: '/', exact: true, component: React.lazy(() => import('src/pages/index')) },
  { path: '/banner/edit/banner/create', exact: true, component: React.lazy(() => import('src/pages/banner/edit/banner/$create')) },
  { path: '/banner/edit/banner/edit/:id', exact: true, component: React.lazy(() => import('src/pages/banner/edit/banner/$create')) },
  { path: '/banner/edit/special/create', exact: true, component: React.lazy(() => import('src/pages/banner/edit/special/$create')) },
  { path: '/banner/edit/special/edit/:id', exact: true, component: React.lazy(() => import('src/pages/banner/edit/special/$create')) },
  { path: '/banner/list', exact: true, component: React.lazy(() => import('src/pages/banner/list')) },
  { path: '/barcodev2', exact: true, component: React.lazy(() => import('src/pages/barcodev2/index')) },
  { path: '/barcodev2/skuv2', exact: true, component: React.lazy(() => import('src/pages/barcodev2/skuv2/index')) },
  { path: '/cart/create', exact: true, component: React.lazy(() => import('src/pages/cart/create')) },
  { path: '/cart/CustomerTokenProvider/CustomerTokenContext', exact: true, component: React.lazy(() => import('src/pages/cart/CustomerTokenProvider/CustomerTokenContext')) },
  { path: '/cart/download_cart_bill', exact: true, component: React.lazy(() => import('src/pages/cart/download_cart_bill')) },
  { path: '/cart/lists', exact: true, component: React.lazy(() => import('src/pages/cart/lists')) },
  { path: '/chat', exact: true, component: React.lazy(() => import('src/pages/chat/index')) },
  { path: '/customers', exact: true, component: React.lazy(() => import('src/pages/customers/index')) },
  { path: '/customers/abandon', exact: true, component: React.lazy(() => import('src/pages/customers/abandon/index')) },
  { path: '/customers/abnormal', exact: true, component: React.lazy(() => import('src/pages/customers/abnormal/index')) },
  { path: '/customers/abnormal/abnormal_shipment', exact: true, component: React.lazy(() => import('src/pages/customers/abnormal/abnormal_shipment')) },
  { path: '/customers/abnormal/abnormal_shipment_solved', exact: true, component: React.lazy(() => import('src/pages/customers/abnormal/abnormal_shipment_solved')) },
  { path: '/customers/abnormal/detail/:id', exact: true, component: React.lazy(() => import('src/pages/customers/abnormal/detail/[id]')) },
  { path: '/customers/bank/approveBankTransfer', exact: true, component: React.lazy(() => import('src/pages/customers/bank/approveBankTransfer')) },
  { path: '/customers/bank/transferBatch/:id', exact: true, component: React.lazy(() => import('src/pages/customers/bank/transferBatch/[id]')) },
  { path: '/customers/edit/:id', exact: true, component: React.lazy(() => import('src/pages/customers/edit/[id]')) },
  { path: '/customers/l192pay', exact: true, component: React.lazy(() => import('src/pages/customers/l192pay/index')) },
  { path: '/customers/order/create/return/:id', exact: true, component: React.lazy(() => import('src/pages/customers/order/create/return/[id]')) },
  { path: '/customers/order/detail/:id', exact: true, component: React.lazy(() => import('src/pages/customers/order/detail/[id]')) },
  { path: '/customers/order/reports/product', exact: true, component: React.lazy(() => import('src/pages/customers/order/reports/product')) },
  { path: '/customers/order/return', exact: true, component: React.lazy(() => import('src/pages/customers/order/return')) },
  { path: '/customers/order/search', exact: true, component: React.lazy(() => import('src/pages/customers/order/search')) },
  { path: '/customers/order/unclaim', exact: true, component: React.lazy(() => import('src/pages/customers/order/unclaim')) },
  { path: '/customers/refund', exact: true, component: React.lazy(() => import('src/pages/customers/refund/index')) },
  { path: '/customers/refund/detail/:id', exact: true, component: React.lazy(() => import('src/pages/customers/refund/detail/[id]')) },
  { path: '/design', exact: true, component: React.lazy(() => import('src/pages/design/index')) },
  { path: '/icon', exact: true, component: React.lazy(() => import('src/pages/icon')) },
  { path: '/inventory', exact: true, component: React.lazy(() => import('src/pages/inventory/index')) },
  { path: '/inventory/finding', exact: true, component: React.lazy(() => import('src/pages/inventory/finding')) },
  { path: '/inventory/finding_request', exact: true, component: React.lazy(() => import('src/pages/inventory/finding_request')) },
  { path: '/inventory/move', exact: true, component: React.lazy(() => import('src/pages/inventory/move')) },
  { path: '/inventory/product/core', exact: true, component: React.lazy(() => import('src/pages/inventory/product/core/index')) },
  { path: '/inventory/product/core/create', exact: true, component: React.lazy(() => import('src/pages/inventory/product/core/create')) },
  { path: '/inventory/product/search/detail/:id', exact: true, component: React.lazy(() => import('src/pages/inventory/product/search/detail/[id]')) },
  { path: '/inventory/removal', exact: true, component: React.lazy(() => import('src/pages/inventory/removal/index')) },
  { path: '/inventory/sku/mark_restock', exact: true, component: React.lazy(() => import('src/pages/inventory/sku/mark_restock')) },
  { path: '/inventory/transaction/transfers', exact: true, component: React.lazy(() => import('src/pages/inventory/transaction/transfers')) },
  { path: '/live/shopping', exact: true, component: React.lazy(() => import('src/pages/live/shopping/index')) },
  { path: '/live/shopping/create', exact: true, component: React.lazy(() => import('src/pages/live/shopping/$create')) },
  { path: '/live/shopping/detail/:id', exact: true, component: React.lazy(() => import('src/pages/live/shopping/detail/[id]')) },
  { path: '/live/shopping/edit/:id', exact: true, component: React.lazy(() => import('src/pages/live/shopping/$create')) },
  { path: '/live/shopping/stock', exact: true, component: React.lazy(() => import('src/pages/live/shopping/stock/index')) },
  { path: '/loginless/generate/checkout', exact: true, component: React.lazy(() => import('src/pages/loginless/generate/checkout')) },
  { path: '/marketing', exact: true, component: React.lazy(() => import('src/pages/marketing/index')) },
  { path: '/marketing/broker', exact: true, component: React.lazy(() => import('src/pages/marketing/broker/index')) },
  { path: '/marketing/campaign_tracker/analytics/:id', exact: true, component: React.lazy(() => import('src/pages/marketing/campaign_tracker/analytics/[id]')) },
  { path: '/marketing/campaign_tracker/create', exact: true, component: React.lazy(() => import('src/pages/marketing/campaign_tracker/$create')) },
  { path: '/marketing/campaign_tracker/edit/:id', exact: true, component: React.lazy(() => import('src/pages/marketing/campaign_tracker/$create')) },
  { path: '/marketing/campaign_tracker/list', exact: true, component: React.lazy(() => import('src/pages/marketing/campaign_tracker/list')) },
  { path: '/marketing/event_campaign/create', exact: true, component: React.lazy(() => import('src/pages/marketing/event_campaign/$create')) },
  { path: '/marketing/event_campaign/edit/:id', exact: true, component: React.lazy(() => import('src/pages/marketing/event_campaign/$create')) },
  { path: '/marketing/event_campaign/join/:id', exact: true, component: React.lazy(() => import('src/pages/marketing/event_campaign/join/[id]')) },
  { path: '/marketing/event_campaign/list', exact: true, component: React.lazy(() => import('src/pages/marketing/event_campaign/list')) },
  { path: '/marketing/payment', exact: true, component: React.lazy(() => import('src/pages/marketing/payment/index')) },
  { path: '/marketing/watermark', exact: true, component: React.lazy(() => import('src/pages/marketing/watermark/index')) },
  { path: '/media/facebook_post', exact: true, component: React.lazy(() => import('src/pages/media/facebook_post')) },
  { path: '/media/list', exact: true, component: React.lazy(() => import('src/pages/media/list/index')) },
  { path: '/online/kpi/list', exact: true, component: React.lazy(() => import('src/pages/online/kpi/list')) },
  { path: '/online/kpi/me', exact: true, component: React.lazy(() => import('src/pages/online/kpi/me')) },
  { path: '/orders', exact: true, component: React.lazy(() => import('src/pages/orders/index')) },
  { path: '/orders/create', exact: true, component: React.lazy(() => import('src/pages/orders/create')) },
  { path: '/orders/detail/:id', exact: true, component: React.lazy(() => import('src/pages/orders/detail/[id]')) },
  { path: '/orders/order_item', exact: true, component: React.lazy(() => import('src/pages/orders/order_item')) },
  { path: '/package/create', exact: true, component: React.lazy(() => import('src/pages/package/create/index')) },
  { path: '/package/customer/pickup', exact: true, component: React.lazy(() => import('src/pages/package/customer/pickup')) },
  { path: '/package/dashboard', exact: true, component: React.lazy(() => import('src/pages/package/dashboard')) },
  { path: '/package/deliverer/deliverer_list', exact: true, component: React.lazy(() => import('src/pages/package/deliverer/deliverer_list')) },
  { path: '/package/deliverer/manage', exact: true, component: React.lazy(() => import('src/pages/package/deliverer/manage')) },
  { path: '/package/deliverer/monitor', exact: true, component: React.lazy(() => import('src/pages/package/deliverer/monitor')) },
  { path: '/package/deliverer/salary', exact: true, component: React.lazy(() => import('src/pages/package/deliverer/salary')) },
  { path: '/package/deliverer/verify_topup', exact: true, component: React.lazy(() => import('src/pages/package/deliverer/verify_topup')) },
  { path: '/package/detail/:id', exact: true, component: React.lazy(() => import('src/pages/package/detail/[id]')) },
  { path: '/package/district/edit/:id', exact: true, component: React.lazy(() => import('src/pages/package/district/edit/[id]')) },
  { path: '/package/district/list', exact: true, component: React.lazy(() => import('src/pages/package/district/list')) },
  { path: '/package/dropoff/create', exact: true, component: React.lazy(() => import('src/pages/package/dropoff/$create')) },
  { path: '/package/dropoff/edit/:id', exact: true, component: React.lazy(() => import('src/pages/package/dropoff/$create')) },
  { path: '/package/dropoff/list', exact: true, component: React.lazy(() => import('src/pages/package/dropoff/list')) },
  { path: '/package/fix-package/:id', exact: true, component: React.lazy(() => import('src/pages/package/fix-package/[id]')) },
  { path: '/package/force/:id', exact: true, component: React.lazy(() => import('src/pages/package/force/[id]')) },
  { path: '/package/generate/label', exact: true, component: React.lazy(() => import('src/pages/package/generate/label')) },
  { path: '/package/image_package', exact: true, component: React.lazy(() => import('src/pages/package/image_package/index')) },
  { path: '/package/manage-announcement/create', exact: true, component: React.lazy(() => import('src/pages/package/manage-announcement/$create')) },
  { path: '/package/manage-announcement/edit/:id', exact: true, component: React.lazy(() => import('src/pages/package/manage-announcement/$create')) },
  { path: '/package/manage-announcement/list', exact: true, component: React.lazy(() => import('src/pages/package/manage-announcement/list')) },
  { path: '/package/map', exact: true, component: React.lazy(() => import('src/pages/package/map/index')) },
  { path: '/package/map/pickup', exact: true, component: React.lazy(() => import('src/pages/package/map/pickup')) },
  { path: '/package/map/staff_location', exact: true, component: React.lazy(() => import('src/pages/package/map/staff_location')) },
  { path: '/package/map/success', exact: true, component: React.lazy(() => import('src/pages/package/map/success')) },
  { path: '/package/overHour', exact: true, component: React.lazy(() => import('src/pages/package/overHour/index')) },
  { path: '/package/pack/checklist/:id', exact: true, component: React.lazy(() => import('src/pages/package/pack/checklist/[id]')) },
  { path: '/package/pack/verify', exact: true, component: React.lazy(() => import('src/pages/package/pack/verify')) },
  { path: '/package/print/mass', exact: true, component: React.lazy(() => import('src/pages/package/print/mass')) },
  { path: '/package/recycle', exact: true, component: React.lazy(() => import('src/pages/package/recycle/index')) },
  { path: '/package/recycle/product_scan_order', exact: true, component: React.lazy(() => import('src/pages/package/recycle/product_scan_order')) },
  { path: '/package/report', exact: true, component: React.lazy(() => import('src/pages/package/report/index')) },
  { path: '/package/report/breakdown', exact: true, component: React.lazy(() => import('src/pages/package/report/breakdown')) },
  { path: '/package/report/cost', exact: true, component: React.lazy(() => import('src/pages/package/report/cost')) },
  { path: '/package/report/hub', exact: true, component: React.lazy(() => import('src/pages/package/report/hub')) },
  { path: '/package/request_pickup/deleted', exact: true, component: React.lazy(() => import('src/pages/package/request_pickup/deleted')) },
  { path: '/package/reroute/:id', exact: true, component: React.lazy(() => import('src/pages/package/reroute/[id]')) },
  { path: '/package/settlement/seller/:id', exact: true, component: React.lazy(() => import('src/pages/package/settlement/seller/[id]')) },
  { path: '/package/settlements', exact: true, component: React.lazy(() => import('src/pages/package/settlements')) },
  { path: '/package/taxi/create', exact: true, component: React.lazy(() => import('src/pages/package/taxi/$create')) },
  { path: '/package/taxi/edit/:id', exact: true, component: React.lazy(() => import('src/pages/package/taxi/$create')) },
  { path: '/package/taxi/manage', exact: true, component: React.lazy(() => import('src/pages/package/taxi/manage')) },
  { path: '/package/transit/log', exact: true, component: React.lazy(() => import('src/pages/package/transit/log/index')) },
  { path: '/package/transit/qr', exact: true, component: React.lazy(() => import('src/pages/package/transit/qr/index')) },
  { path: '/package/unsettle', exact: true, component: React.lazy(() => import('src/pages/package/unsettle')) },
  { path: '/package/walkIn', exact: true, component: React.lazy(() => import('src/pages/package/walkIn/index')) },
  { path: '/partner/disbursement/detail/:id', exact: true, component: React.lazy(() => import('src/pages/partner/disbursement/detail/[id]')) },
  { path: '/partner/disbursement/list', exact: true, component: React.lazy(() => import('src/pages/partner/disbursement/list/index')) },
  { path: '/payments', exact: true, component: React.lazy(() => import('src/pages/payments/index')) },
  { path: '/payments/detail/:id', exact: true, component: React.lazy(() => import('src/pages/payments/detail/[id]')) },
  { path: '/printing', exact: true, component: React.lazy(() => import('src/pages/printing')) },
  { path: '/product/analytics', exact: true, component: React.lazy(() => import('src/pages/product/analytics')) },
  { path: '/product/detail/:id/stock', exact: true, component: React.lazy(() => import('src/pages/product/detail/[id]/stock')) },
  { path: '/product/edit/:id', exact: true, component: React.lazy(() => import('src/pages/product/edit/[id]')) },
  { path: '/product/review', exact: true, component: React.lazy(() => import('src/pages/product/review/index')) },
  { path: '/product/review/item/:id', exact: true, component: React.lazy(() => import('src/pages/product/review/item/[id]')) },
  { path: '/product/title/edit/:id', exact: true, component: React.lazy(() => import('src/pages/product/title/edit/[id]')) },
  { path: '/product/title/list', exact: true, component: React.lazy(() => import('src/pages/product/title/list')) },
  { path: '/promo', exact: true, component: React.lazy(() => import('src/pages/promo/index')) },
  { path: '/promo/campaign/:id/code', exact: true, component: React.lazy(() => import('src/pages/promo/campaign/[id]/code')) },
  { path: '/promo/campaign/create', exact: true, component: React.lazy(() => import('src/pages/promo/campaign/$create')) },
  { path: '/promo/campaign/edit/:id', exact: true, component: React.lazy(() => import('src/pages/promo/campaign/$create')) },
  { path: '/promo/code/create', exact: true, component: React.lazy(() => import('src/pages/promo/code/$create')) },
  { path: '/promo/code/edit/:id', exact: true, component: React.lazy(() => import('src/pages/promo/code/$create')) },
  { path: '/purchase/create/mass2', exact: true, component: React.lazy(() => import('src/pages/purchase/create/mass2')) },
  { path: '/purchase/list', exact: true, component: React.lazy(() => import('src/pages/purchase/list')) },
  { path: '/referral/detail/:id', exact: true, component: React.lazy(() => import('src/pages/referral/detail/[id]/index')) },
  { path: '/referral/disbursement', exact: true, component: React.lazy(() => import('src/pages/referral/disbursement')) },
  { path: '/referral/list', exact: true, component: React.lazy(() => import('src/pages/referral/list')) },
  { path: '/reports/audits/daily_transaction', exact: true, component: React.lazy(() => import('src/pages/reports/audits/daily_transaction')) },
  { path: '/reports/cohort', exact: true, component: React.lazy(() => import('src/pages/reports/cohort')) },
  { path: '/reports/custom/create', exact: true, component: React.lazy(() => import('src/pages/reports/custom/$create')) },
  { path: '/reports/custom/edit/:id', exact: true, component: React.lazy(() => import('src/pages/reports/custom/$create')) },
  { path: '/reports/custom/list', exact: true, component: React.lazy(() => import('src/pages/reports/custom/list')) },
  { path: '/reports/custom/run/:id', exact: true, component: React.lazy(() => import('src/pages/reports/custom/run/[id]')) },
  { path: '/reports/dashboard', exact: true, component: React.lazy(() => import('src/pages/reports/dashboard')) },
  { path: '/reports/kpi/flow', exact: true, component: React.lazy(() => import('src/pages/reports/kpi/flow')) },
  { path: '/reports/monitor', exact: true, component: React.lazy(() => import('src/pages/reports/monitor/index')) },
  { path: '/reports/monthly_returned', exact: true, component: React.lazy(() => import('src/pages/reports/monthly_returned/index')) },
  { path: '/reports/product_impression', exact: true, component: React.lazy(() => import('src/pages/reports/product_impression/index')) },
  { path: '/setting', exact: true, component: React.lazy(() => import('src/pages/setting/index')) },
  { path: '/setting/devlivery', exact: true, component: React.lazy(() => import('src/pages/setting/devlivery/index')) },
  { path: '/setting/district/edit/:id', exact: true, component: React.lazy(() => import('src/pages/setting/district/edit/[id]')) },
  { path: '/setting/district/list', exact: true, component: React.lazy(() => import('src/pages/setting/district/list')) },
  { path: '/setting/generic/:option_name', exact: true, component: React.lazy(() => import('src/pages/setting/generic/[option_name]')) },
  { path: '/setting/note_template/create', exact: true, component: React.lazy(() => import('src/pages/setting/note_template/$create')) },
  { path: '/setting/note_template/edit/:id', exact: true, component: React.lazy(() => import('src/pages/setting/note_template/$create')) },
  { path: '/setting/note_template/list', exact: true, component: React.lazy(() => import('src/pages/setting/note_template/list')) },
  { path: '/setting/promo_code_limit', exact: true, component: React.lazy(() => import('src/pages/setting/promo_code_limit')) },
  { path: '/staff/detail/:id', exact: true, component: React.lazy(() => import('src/pages/staff/detail/[id]')) },
  { path: '/staff/list', exact: true, component: React.lazy(() => import('src/pages/staff/list')) },
  { path: '/store/dashboard', exact: true, component: React.lazy(() => import('src/pages/store/dashboard')) },
  { path: '/store/pos', exact: true, component: React.lazy(() => import('src/pages/store/pos/index')) },
  { path: '/supplier/ads/:id', exact: true, component: React.lazy(() => import('src/pages/supplier/ads/[id]')) },
  { path: '/supplier/ads/order/:id', exact: true, component: React.lazy(() => import('src/pages/supplier/ads/order/[id]')) },
  { path: '/supplier/affiliate/detail/:id', exact: true, component: React.lazy(() => import('src/pages/supplier/affiliate/detail/[id]')) },
  { path: '/supplier/affiliate/list', exact: true, component: React.lazy(() => import('src/pages/supplier/affiliate/list')) },
  { path: '/supplier/detail/:id', exact: true, component: React.lazy(() => import('src/pages/supplier/detail/[id]/index')) },
  { path: '/supplier/detail/:id/supplier_fee', exact: true, component: React.lazy(() => import('src/pages/supplier/detail/[id]/supplier_fee')) },
  { path: '/supplier/disbursement/detail/:id', exact: true, component: React.lazy(() => import('src/pages/supplier/disbursement/detail/[id]')) },
  { path: '/supplier/disbursement/list', exact: true, component: React.lazy(() => import('src/pages/supplier/disbursement/list')) },
  { path: '/supplier/pickup', exact: true, component: React.lazy(() => import('src/pages/supplier/pickup/index')) },
  { path: '/supplier/register', exact: true, component: React.lazy(() => import('src/pages/supplier/register/index')) },
  { path: '/supplier/shipment/detail/:id', exact: true, component: React.lazy(() => import('src/pages/supplier/shipment/detail/[id]')) },
  { path: '/supplier/shipment/list', exact: true, component: React.lazy(() => import('src/pages/supplier/shipment/list/index')) },
  { path: '/supplier/upload_draft', exact: true, component: React.lazy(() => import('src/pages/supplier/upload_draft/index')) },
  { path: '/supplier/upload_draft/:id', exact: true, component: React.lazy(() => import('src/pages/supplier/upload_draft/[id]')) },
  { path: '/tasks', exact: true, component: React.lazy(() => import('src/pages/tasks/index')) },
  { path: '/template/create', exact: true, component: React.lazy(() => import('src/pages/template/create')) },
  { path: '/template/edit/:id', exact: true, component: React.lazy(() => import('src/pages/template/edit/[id]')) },
  { path: '/template/list', exact: true, component: React.lazy(() => import('src/pages/template/list')) },
];

export default GeneratedRoute
