import { imagePackageMenu } from 'src/components/menuItemImagePackage';
import { DateTime } from 'src/libs/DateTime';

// navigation application
export function navigationForAll(isSuperAdmin: boolean, groupId: number) {
  const deliveryMenu =
    isSuperAdmin || [3, 23].indexOf(groupId) >= 0
      ? [
          {
            label: 'Deliverer',
            url: '/package/deliverer/deliverer_list',
            icon: 'fas fa-dollar-sign',
            subs: [
              { label: 'Deliverer Balance', url: '/package/deliverer/deliverer_list' },
              {
                label: 'Topup Summary',
                url: `/reports/custom/run/91?__auto=1&start=${DateTime.lastMonth()}&end=${DateTime.tomorrow()}`,
              },
              { label: 'Verify Topup', url: '/package/deliverer/verify_topup' },
            ],
          },
        ]
      : [];

  return [
    {
      title: 'Inventory',
      items: [
        {
          label: 'Point of Sales',
          url: '/store/dashboard',
          icon: 'fas fa-laptop',
        },
        {
          label: 'Stock Movement',
          url: '/inventory/stockin',
          icon: 'os-icon os-icon-layout',
          subs: [
            { label: 'Stock In  (PO)', url: '/inventory/stockin' },
            { label: 'Stock In (Shipment)', url: '/inventory/stockin_fbl' },
            { label: 'Transfer', url: '/inventory/transfer' },
            { label: 'Recycle', url: '/inventory/recycle' },
            { label: 'Adjust', url: '/inventory/adjust' },
            { label: 'Fixed', url: '/inventory/fixed' },
            { label: 'Finding', url: '/inventory/finding' },
            { label: 'Finding (Shop Request)', url: '/inventory/finding_request' },
            { label: 'Temporary Move', url: '/inventory/move' },
            { label: 'Create Barcode', url: '/barcode/create' },
            { label: 'Create Barcode V2', url: '/barcodev2' },
            { label: 'Stock Transaction', url: '/inventory/stock_transation' },
            { label: 'Transfer History', url: '/inventory/transaction/transfers' },
            { label: 'Live stock status', url: '/live/shopping/stock' },
          ],
        },
        {
          label: 'Purchase Order',
          icon: 'os-icon os-icon-delivery-box-2',
          url: '/purchase/list',
          subs: [
            { label: 'Create Purchase Order', url: '/purchase/create' },
            { label: 'Create Mass Purchase Order', url: '/purchase/create/mass2' },
            { label: 'Manage Purchase Order', url: '/purchase/list' },
          ],
        },
        {
          label: 'Inventory',
          url: '/inventory',
          icon: 'os-icon os-icon-window-content',
        },
      ],
    },
    {
      title: 'Delivery',
      items: [
        {
          label: 'Control Panel',
          url: '/package/dashboard',
          icon: 'os-icon os-icon-mail-18',
        },
        ...imagePackageMenu().map(x => {
          return {
            label: x.name,
            url: x.to,
            icon: x.icon,
            badge: x.badge,
          };
        }),
        {
          label: 'Scanning',
          url: '/package/scan',
          icon: 'fas fa-qrcode',
          subs: [
            { label: 'Scan Package', url: '/package/scan' },
            { label: 'Scan In', url: '/package/scanin' },
            { label: 'Scan Out', url: '/package/scanout' },
            { label: 'Transit QR', url: '/package/transit/qr' },
          ],
        },
        {
          label: 'Settlement',
          url: '/package/unsettle#request',
          icon: 'fas fa-dollar-sign',
          subs: [
            { label: 'COD Request', url: '/package/unsettle#request' },
            { label: 'Settlement Log', url: '/package/settlements#seller' },
          ],
        },
      ],
      ...deliveryMenu,
    },
    {
      title: 'Customer',
      items: [
        {
          label: 'Create Order',
          url: '/orders/create',
          // exactMatch: true,
          icon: 'os-icon os-icon-delivery-box-2',
        },
        {
          label: 'Cart',
          url: '/cart/create',
          // excludePaths: ['/cart/create'],
          icon: 'os-icon os-icon-finance-29',
        },
        {
          label: 'Cart List',
          url: '/cart/lists',
          // excludePaths: ['/cart/create'],
          icon: 'os-icon os-icon-shopping-cart',
        },
        {
          label: 'Orders',
          url: '/orders',
          // excludePaths: ['/orders/create'],
          icon: 'os-icon os-icon-shopping-bag',
        },
        {
          label: 'Order Items',
          url: '/orders/order_item',
          // excludePaths: ['/orders/create'],
          icon: 'os-icon os-icon-shopping-bag',
        },
        {
          label: 'Payments',
          url: '/payments',
          // excludePaths: ['/payments'],
          icon: 'fa fa-credit-card',
        },
        {
          label: 'Customer',
          url: '/customers',
          icon: 'os-icon os-icon-users',
          subs: [
            {
              label: 'Voices',
              url: '/voice/list',
            },
            {
              label: 'Search Order',
              url: '/customers/order/search',
            },
            {
              label: 'Refund',
              url: '/customers/refund',
            },
            { label: 'Customer List', url: '/customers' },
            { label: 'Abandoned Cart', url: '/cart/abandoned' },
            {
              label: 'Likely Abandon List',
              url: '/customers/abandon',
            },
            { label: 'Monitor Order', url: '/reports/monitor' },
            {
              label: 'Unverified Bank Transfer',
              url: '/customers/bank/approveBankTransfer',
            },
            {
              label: 'Order Return',
              url: '/customers/order/return',
            },
            {
              label: 'Reported Product',
              url: '/customers/order/reports/product',
            },
          ],
        },
        {
          label: 'Media lists',
          icon: 'fa fa-video',
          url: '/media/list',
        },
        {
          label: 'Comments',
          url: '/comments',
          icon: 'fas fa-comments',
        },
        {
          label: 'Tasks',
          url: '/tasks',
          icon: 'fa fa-columns',
        },
      ],
    },
    {
      title: 'Disbursement',
      items: [
        {
          label: 'Affiliate Disbursement',
          url: '/supplier/affiliate/list',
          icon: 'os-icon os-icon-wallet-loaded',
        },
        { label: 'Partner Disbursement', url: '/partner/disbursement/list', icon: 'os-icon os-icon-wallet-loaded' },
        {
          label: 'Referral Disbursement',
          icon: 'os-icon os-icon-wallet-loaded',
          url: '/referral/disbursement#0',
          // subs: [
          //   { label: 'Available Earning', url: '/referral/list' },
          //   { label: 'Disbursement', url: '/referral/disbursement#0' },
          // ],
        },
      ],
    },
    {
      title: 'Other',
      items: [
        {
          label: 'Manage Sellers',
          url: '/supplier/list',
          icon: 'fa fa-users',
          subs: [
            {
              label: 'Manage Sellers',
              url: '/supplier/list',
            },
            {
              label: 'Register Seller',
              url: '/supplier/register',
            },
            {
              label: 'Announcement',
              url: '/package/manage-announcement/list',
            },
            {
              label: 'Supplier Disbursement',
              url: '/supplier/disbursement/list',
            },
            {
              label: 'Supplier Product Review',
              url: '/supplier/upload_draft',
            },
            {
              label: 'Shipment',
              url: '/supplier/shipment/list',
            },
            {
              label: 'Abnormal Shipment',
              url: '/customers/abnormal',
            },
          ],
        },
        {
          label: 'Marketing',
          url: '/marketing',
          icon: 'fa fa-diamond',
        },
        {
          label: 'Multiple Offers Template',
          url: '/template/list',
          icon: 'os-icon os-icon-star',
        },
      ],
    },
    {
      title: 'Report',
      items: [
        {
          label: 'Reports',
          url: '/reports/dashboard',
          icon: 'os-icon os-icon-agenda-1',
        },
        {
          label: 'Custom Reports',
          url: '/reports/custom/list',
          icon: 'os-icon os-icon-agenda-1',
        },
        {
          label: 'Staff Log Location',
          icon: 'os-icon os-icon-newspaper',
          url: '/package/map/staff_location',
        },
        {
          label: 'Product Impression',
          icon: 'os-icon os-icon-newspaper',
          url: '/reports/product_impression',
        },
      ],
    },
    {
      title: 'Setting',
      items: [
        {
          label: 'Setting',
          url: '/setting',
          icon: 'os-icon os-icon-settings',
          subs: [
            { label: 'Setting', url: '/setting' },
            { label: 'Category', url: '/category/list' },
            { label: 'Admin Group', url: '/admingroup/list' },
            { label: 'Note Template', url: '/note/templates' },
            { label: 'Staff List', url: '/staff/list' },
          ],
        },
      ],
    },
  ];
}
