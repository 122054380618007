/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropZone, Icon, Modal, Spinner, Thumbnail } from '@shopify/polaris';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CameraMajor, NoteMajor } from '@shopify/polaris-icons';
import { gql, useQuery } from '@apollo/client';
import { useCustomToast } from 'src/contexts/ToastProvider';
import { getResizer } from 'src/pages/product/edit/components/lib/getResizer';
import { useSearchImageProduct } from 'src/contexts/SearchImageProductProvider';

const QUERY = gql`
  query Upload($file: Upload!) {
    productImageSearch(file: $file)
  }
`;

const QUERY_SEARCH_IMAGE_RESULT = gql`
  query productImageSearchResult($hash: String!) {
    productImageSearchResult(hash: $hash) {
      id
    }
  }
`;

interface Props {
  onImageSearchResult: (ids: number[], file?: File) => void;
}

export function ImageSearchPopup(props: Props) {
  // const sleep = (number: number) => new Promise(resolve => setTimeout(resolve, number));
  const { toasts, setToasts } = useCustomToast();
  // const [file, setFile] = useState<File>();
  const { file, setFile, hash, setHash, setProductIds, productIds } = useSearchImageProduct();
  const [active, setActive] = useState(false);
  // const [hash, setHash] = useState(null);
  const clipboardRef = useRef<HTMLInputElement>(null);

  const propsUpload = useQuery(QUERY, {
    skip: !file,
    variables: {
      file,
    },
    onCompleted: res => {
      if (res.productImageSearch) {
        setHash(res.productImageSearch);
      } else {
        setToasts([...toasts, { content: 'Not found!', status: 'error' }]);
        setFile(null);
        setHash(null);
        setProductIds([]);
      }
    },
    onError: () => {
      setToasts([...toasts, { content: 'Something was wrong please try again!', status: 'error' }]);
      setFile(null);
      setHash(null);
      setProductIds([]);
    },
  });

  useQuery(QUERY_SEARCH_IMAGE_RESULT, {
    skip: !hash,
    variables: {
      hash,
    },
    onCompleted: res => {
      if (res.productImageSearchResult) {
        props.onImageSearchResult(
          res.productImageSearchResult.map((x: { id: number }) => x.id),
          file,
        );
        setProductIds(res.productImageSearchResult.map((x: { id: number }) => x.id));
        setTimeout(() => {
          setActive(false);
        }, 500);
      } else {
        setToasts([...toasts, { content: 'Not found!', status: 'error' }]);
        setFile(null);
        setHash(null);
        setProductIds([]);
      }
    },
    onError: () => {
      setToasts([...toasts, { content: 'Something was wrong please try again!', status: 'error' }]);
      setFile(null);
      setHash(null);
      setProductIds([]);
    },
  });

  useEffect(() => {
    if (productIds.length > 0) {
      setTimeout(() => {
        props.onImageSearchResult(productIds, file);
      }, 500);
    }
  }, [productIds]);

  const toggleActive = useCallback(() => setActive(!active), [active]);

  const activator = (
    <div className="cursor-pointer" onClick={toggleActive}>
      <Icon source={CameraMajor} color="base" />
    </div>
  );

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !file && !propsUpload.loading && (
    <DropZone.FileUpload actionTitle="Upload" actionHint="Drag an image here or upload a file" />
  );

  const fileUploading = !!file && !!propsUpload.loading && (
    <div className="flex flex-col justify-center h-full w-full items-center">
      <Spinner size="small" />
      <small className="text-gray-400">Uploading...</small>
    </div>
  );

  const uploadedFile = !!file && !propsUpload.loading && (
    <div className="flex flex-col justify-center h-full w-full items-center">
      <Thumbnail
        size="small"
        alt={file.name}
        source={validImageTypes.includes(file.type) ? window.URL.createObjectURL(file) : NoteMajor}
      />
    </div>
  );

  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    if (_rejectedFiles.length) {
      for (const f of _rejectedFiles) {
        setToasts([...toasts, { content: `Rejected file : ${f.name}`, status: 'error' }]);
      }
    }
    setFile(acceptedFiles[0]);
  }, []);

  // const convertUrltoImage = (url: string) => {
  //   fetch(url)
  //     .then(res => res.blob())
  //     .then(blob => {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         console.log(reader.result);
  //         // props.onSuccess({
  //         //   src: reader.result,
  //         //   iscrop: false,
  //         // });
  //       };
  //       reader.readAsDataURL(blob);
  //     });
  // };

  const onPaste = (e: any) => {
    if (e.clipboardData.files) {
      if (e.clipboardData.files.length) {
        const fileObject = e.clipboardData.files[0];
        getResizer(fileObject).then(res => {
          setFile(res as any);
        });
      }
    }
  };

  return (
    <Modal
      activator={activator}
      open={active}
      onClose={() => {
        // setFile(undefined);
        // setHash(null);
        !propsUpload.loading && toggleActive();
      }}
      title="Search any image"
    >
      <Modal.Section>
        <div>
          <DropZone
            accept="image/*"
            type="image"
            allowMultiple={false}
            onDrop={handleDropZoneDrop}
            disabled={propsUpload.loading}
          >
            {fileUploading}
            {fileUpload}
            {uploadedFile}
          </DropZone>
        </div>
        <div className="flex flex-row justify-between gap-1 my-2 items-center">
          <div className="w-[45%] border-collapse border-solid border-b-[0.5px]"></div>
          <div>OR</div>
          <div className="w-[45%] border-collapse border-solid border-b-[0.5px]"></div>
        </div>
        <div className="flex flex-row justify-between gap-1">
          <div className="w-[100%]">
            <input
              type="text"
              placeholder="Paste your image here"
              className="Polaris-TextField__Input"
              style={{
                margin: 5,
                padding: 3,
                border: '0.04125rem solid rgba(138, 138,138,1)',
                borderRadius: '0.5rem',
              }}
              // onKeyDown={async e => {
              //   if (e.key === 'Enter' && clipboardRef.current?.value) {
              //     await convertUrltoImage(clipboardRef.current?.value);
              //     clipboardRef.current.value = '';
              //   }
              // }}
              defaultValue=""
              ref={clipboardRef}
              onPaste={onPaste}
              // disabled={propsUpload.loading}
            />
          </div>
          {/* <div>
            <Button disabled={propsUpload.loading} loading={propsUpload.loading}>
              Search
            </Button>
          </div> */}
        </div>
      </Modal.Section>
    </Modal>
  );
}
