import { imagePackageMenu } from './components/menuItemImagePackage';
import { DateTime } from './libs/DateTime';

// navigation application
export default function getMenuItems(isSuperAdmin: boolean, groupId: number) {
  const deliveryMenu =
    isSuperAdmin || [3, 23].indexOf(groupId) >= 0
      ? [
          {
            name: 'Deliverer',
            to: '#',
            icon: 'fas fa-dollar-sign',
            subs: [
              { name: 'Deliverer Balance', to: '/package/deliverer/deliverer_list' },
              {
                name: 'Topup Summary',
                to: `/reports/custom/run/91?__auto=1&start=${DateTime.lastMonth()}&end=${DateTime.tomorrow()}`,
              },
              { name: 'Verify Topup', to: '/package/deliverer/verify_topup' },
            ],
          },
        ]
      : [];

  return [
    {
      name: 'Inventory',
      to: '#',
      header: true,
    },
    {
      name: 'Stock Movement',
      icon: 'os-icon os-icon-layout',
      to: '#',
      subs: [
        [
          { name: 'Stock In  (PO)', to: '/inventory/stockin' },
          { name: 'Stock In (Shipment)', to: '/inventory/stockin_fbl' },
          { name: 'Transfer', to: '/inventory/transfer' },
          { name: 'Recycle', to: '/inventory/recycle' },
          { name: 'Adjust', to: '/inventory/adjust' },
          { name: 'Fixed', to: '/inventory/fixed' },
          { name: 'Finding', to: '/inventory/finding' },
          { name: 'Finding (Shop Request)', to: '/inventory/finding_request' },
          { name: 'Temporary Move', to: '/inventory/move' },
        ],
        [
          { name: 'Point of Sales', to: '#' },
          { name: 'Create Barcode', to: '/barcode/create' },
          { name: 'Create Barcode V2', to: '/barcodev2' },
          { name: 'Stock Transaction', to: '/inventory/stock_transation' },
          { name: 'Transfer History', to: '/inventory/transaction/transfers' },
          { name: 'Live stock status', to: '/live/shopping/stock' },
        ],
      ],
    },
    {
      name: 'Purchase Order',
      header: false,
      icon: 'os-icon os-icon-delivery-box-2',
      to: '#',
      subs: [
        { name: 'Create Purchase Order', to: '/purchase/create' },
        { name: 'Create Mass Purchase Order', to: '/purchase/create/mass2' },
        { name: 'Manage Purchase Order', to: '/purchase/list' },
      ],
    },
    {
      name: 'Inventory',
      icon: 'os-icon os-icon-window-content',
      to: '/inventory',
      // subs: [
      //   { name: 'Customer Slot', to: '/customer_slot/list' },
      //   { name: 'Inventory', to: '/inventory/list' },
      //   { name: 'Manage Product', to: '/product/search' },
      //   { name: 'Core Product', to: '/inventory/product/core' },
      //   { name: 'Monitor Stock', to: '/report/recommend/restock' },
      //   { name: 'Product Short Title', to: '/product/title/list' },
      //   { name: 'Product Live Report', to: '/report/product/live' },
      //   { name: 'Supplier Ads Campaign', to: '/supplier/ads' },
      //   { name: 'Inventory Removal', to: '/inventory/removal' },
      //   { name: 'Supplier Product Recycle', to: '/package/recycle#recycle' },
      // ],
    },
    {
      name: 'Delivery',
      to: '#',
      header: true,
    },
    {
      name: 'Control Panel',
      to: '/package/dashboard',
      icon: 'os-icon os-icon-mail-18',
    },
    ...imagePackageMenu(),
    {
      name: 'Scanning',
      to: '#',
      icon: 'fas fa-qrcode',
      subs: [
        { name: 'Scan Package', to: '/package/scan' },
        { name: 'Scan In', to: '/package/scanin' },
        { name: 'Scan Out', to: '/package/scanout' },
        { name: 'Transit QR', to: '/package/transit/qr' },
      ],
    },
    {
      name: 'Settlement',
      to: '#',
      icon: 'fas fa-dollar-sign',
      subs: [
        { name: 'COD Request', to: '/package/unsettle#request' },
        { name: 'Settlement Log', to: '/package/settlements#seller' },
      ],
    },
    ...deliveryMenu,
    {
      name: 'Disbursements',
      to: '#',
      header: true,
    },
    {
      name: 'Affiliate Disbursement',
      icon: 'fas fa-dollar-sign',
      to: '/supplier/affiliate/list',
    },
    {
      name: 'Partner Disbursement',
      icon: 'fas fa-dollar-sign',
      to: '/partner/disbursement/list',
    },
    {
      name: 'Referral Disbursement',
      icon: 'fas fa-dollar-sign',
      to: '/referral/disbursement#0',
      // subs: [
      //   { name: 'Available Earning', to: '/referral/list' },
      //   { name: 'Disbursement', to: '/referral/disbursement#0' },
      // ],
    },
    {
      name: 'Customer Services',
      to: '#',
      header: true,
    },
    {
      name: 'Comments',
      icon: 'os-icon os-icon-star',
      to: '/comments',
    },
    {
      name: 'Order Items',
      to: '/orders/order_item',
      // excludePaths: ['/orders/create'],
      icon: 'os-icon os-icon-shopping-bag',
    },
    {
      name: 'Customers',
      icon: 'os-icon os-icon-user-male-circle',
      to: '#',
      subs: [
        {
          name: 'Voices',
          to: '/voice/list',
        },
        {
          name: 'Search Order',
          to: '/customers/order/search',
        },
        {
          name: 'Refund',
          to: '/customers/refund',
        },
        { name: 'Customer List', to: '/customers' },
        { name: 'Abandoned Cart', to: '/cart/abandoned' },
        {
          name: 'Likely Abandon List',
          to: '/customers/abandon',
        },
        { name: 'Monitor Order', to: '/reports/monitor' },
        {
          name: 'Unverified Bank Transfer',
          to: '/customers/bank/approveBankTransfer',
        },
        {
          name: 'Order Return',
          to: '/customers/order/return',
        },
        {
          name: 'Reported Product',
          to: '/customers/order/reports/product',
        },
      ],
    },
    {
      name: 'Media lists',
      icon: 'os-icon os-icon-media',
      to: '/media/list',
    },
    {
      name: 'Other',
      to: '#',
      header: true,
    },
    {
      name: 'Manage Sellers',
      icon: 'fas fa-store',
      to: '#',
      subs: [
        {
          name: 'Manage Sellers',
          to: '/supplier/list',
        },
        {
          name: 'Register Seller',
          to: '/supplier/register',
        },
        {
          name: 'Announcement',
          to: '/package/manage-announcement/list',
        },
        {
          name: 'Supplier Disbursement',
          to: '/supplier/disbursement/list',
        },
        {
          name: 'Supplier Product Review',
          to: '/supplier/upload_draft',
        },
        {
          name: 'Shipment',
          to: '/supplier/shipment/list',
        },
        {
          name: 'Abnormal Shipment',
          to: '/customers/abnormal',
        },
      ],
    },
    {
      name: 'Marketing',
      to: '/marketing',
      icon: 'os-icon os-icon-star',
    },
    {
      name: 'Multiple Offers Template',
      to: '/template/list',
      icon: 'os-icon os-icon-star',
    },
    // {
    //   name: 'Abnormal Shipment',
    //   to: '/customers/abnormal',
    //   icon: 'os-icon os-icon-star',
    // },
    // {
    //   name: 'Create Customer Order',
    //   to: '/customers/create_order',
    //   icon: 'os-icon os-icon-star',
    // },
    {
      name: 'Report',
      to: '#',
      header: true,
    },
    {
      name: 'Reports',
      icon: 'os-icon os-icon-newspaper',
      to: '/reports/dashboard',
    },
    {
      name: 'Custom Reports',
      icon: 'os-icon os-icon-newspaper',
      to: '/reports/custom/list',
    },
    {
      name: 'Staff Log Location',
      icon: 'os-icon os-icon-newspaper',
      to: '/package/map/staff_location',
    },
    {
      name: 'Product Impression',
      icon: 'os-icon os-icon-newspaper',
      to: '/reports/product_impression',
    },
    {
      name: 'Setting',
      to: '#',
      header: true,
    },
    {
      name: 'Setting',
      icon: 'os-icon os-icon-newspaper',
      to: '#',
      subs: [
        { name: 'Setting', to: '/setting' },
        { name: 'Category', to: '/category/list' },
        { name: 'Admin Group', to: '/admingroup/list' },
        { name: 'Note Template', to: '/note/templates' },
        { name: 'Staff List', to: '/staff/list' },
      ],
    },
  ];
}
